import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import { ACTIVE_PAGE } from "../components/nav/utils";
import DropdownMenu from "../components/DropdownMenu";
import { useEffect, useState } from "react";
import { isMp4 } from "../utils/functions";
import {
  BlogHeaderImage,
  BlogHeaderVideo,
  CardTagButton,
} from "../page-components/blog/styles";
import ShareButtons from "../components/sharebuttons";
import ContactRequestContainer from "../page-components/blog/ContactRequestContainer/ContactRequestContainer";
import { threeDLinks } from "./3D";
import { kebabCase } from "lodash";
import {
  Content,
  ContentWrapper,
  Divider,
  HeaderContainer,
  PostHeader,
} from "../page-components/blog/BlogPost.styles";

interface MediaComponentProps {
  mediaSrc: string;
  imageFocus?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({
  mediaSrc,
  imageFocus = "",
}) => {
  if (!mediaSrc) return null;

  return isMp4(mediaSrc) ? (
    <BlogHeaderVideo loop autoPlay muted style={{ objectPosition: imageFocus }}>
      <source src={mediaSrc} type="video/mp4" />
    </BlogHeaderVideo>
  ) : (
    <BlogHeaderImage
      src={mediaSrc}
      alt=""
      style={{ objectPosition: imageFocus }}
    />
  );
};

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 0;

  const titles = threeDLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function ThreeDPage() {
  const [linkIndex, setLinkIndex] = useState<number | null>(null);
  const [url, setUrl] = useState("");
  const tags = ["As-Built Documentation", "3D Walkthrough", "Scan-to-BIM"];

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.THREEDBLOG}>
      <Seo
        title="BIM Modeling Services | Robotic Imaging"
        description="Sample Models from BIM Services provided by Robotic Imaging."
      />
      <div style={{ padding: "60px 0 0 0" }}></div>
      <Divider />

      <HeaderContainer>
        <PostHeader>Standard 3D Revit Delivery</PostHeader>
      </HeaderContainer>

      <ContentWrapper>
        <Content>
          <MediaComponent mediaSrc="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2F3D%2FTRIMMED%20-%20640%20Broad%20St%20Church_Facade%20(1).mp4?alt=media&token=52dcd312-5f32-4aa8-98c7-e8ce0da9fc3a" />
          <br />
          <small>
            {tags.map((tag, i) => (
              <CardTagButton key={tag}>
                <small>
                  <Link
                    className="blog-post__tag-link"
                    to={`/tags/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Link>
                  {i < tags.length - 1 ? "" : ""}
                </small>
              </CardTagButton>
            ))}
          </small>
          <small className="small_text">Robotic Imaging</small>
          <small className="small_text">March 31, 2024</small>

          <small style={{ paddingTop: "10px" }}>
            <ShareButtons
              title={"Standard 3D Revit Delivery"}
              url={url}
              tags={tags}
            />
          </small>
          <br />

          <div className="site__header__button-container-tags">
            {threeDLinks.map((link, index) => (
              <button
                key={link.title}
                onClick={() => handleClick(link.title, index)}
                disabled={linkIndex === index}
                className={`site__header__button${
                  linkIndex === index ? "-disabled" : ""
                }`}
              >
                {link.title}
              </button>
            ))}
          </div>

          <DropdownMenu
            index={linkIndex}
            setIndex={setLinkIndex}
            options={threeDLinks}
          />
          <iframe
            className="deliverable__iframe"
            title="three-d-drawing"
            src={linkIndex !== null ? threeDLinks[linkIndex].url : ""}
            frameBorder="0"
          />
          <div className={"blog-post__html"}>
            <h2>3D Robotic Imaging</h2>

            <p>
              <b>Retailers, Designers, Architects, Engineers</b> and{" "}
              <b>Construction</b> teams use our 3D Imaging to standardize quick
              and efficient as-built delivery in all markets. From{" "}
              <u>multi-site</u> programs to <u>1-off adaptive reuse projects</u>
              , we help our clients in a variety of ways:
            </p>

            <ol>
              <li>
                <strong>Accurate As-Built Documentation</strong>
                <p>
                  3D laser scanning provides highly accurate measurements of
                  existing structures and construction sites, enabling precise
                  as-built documentation. This data is invaluable for design,
                  retrofitting, and maintenance.
                </p>
              </li>

              <li>
                <strong>Time and Cost Savings</strong>
                <p>
                  By rapidly capturing detailed information, laser scanning can
                  significantly reduce the time and cost associated with manual
                  measurements, site revisits, and rework. It helps in keeping
                  projects on schedule and within budget.
                </p>
              </li>

              <li>
                <strong>Improved Project Management</strong>
                <p>
                  Construction managers can use 3D laser scanning to monitor
                  progress, detect discrepancies between plans and actual work,
                  and make data-driven decisions to optimize project timelines
                  and resource allocation.
                </p>
              </li>

              <li>
                <strong>Enhanced Design and Planning</strong>
                <p>
                  Scanned data can be used to create accurate 3D models, aiding
                  in design and planning. Architects and engineers can work with
                  more precise information, reducing errors and changes during
                  construction.
                </p>
              </li>

              <li>
                <strong>Quality Control and Assurance</strong>
                <p>
                  Laser scanning helps identify construction deviations and
                  quality issues early in the process, ensuring that the final
                  product meets design specifications and regulatory
                  requirements.
                </p>
              </li>

              <li>
                <strong>Safety and Risk Mitigation</strong>
                <p>
                  Scanning technology allows for remote site inspections and
                  minimizes the need for workers to enter hazardous areas. This
                  enhances overall job site safety and reduces potential
                  liability.
                </p>
              </li>

              <li>
                <strong>Environmental Impact</strong>
                <p>
                  By minimizing the need for on-site revisits and rework, 3D
                  laser scanning can reduce the environmental footprint of
                  construction projects.
                </p>
              </li>

              <li>
                <strong>Communication and Collaboration</strong>
                <p>
                  3D laser scanning data can be easily shared among project
                  stakeholders, promoting effective communication and
                  collaboration among architects, engineers, contractors, and
                  clients.
                </p>
              </li>

              <li>
                <strong>Facilities Management</strong>
                <p>
                  After construction is complete, the detailed 3D scans can be
                  utilized for facilities management, maintenance, and
                  renovations, extending the value of the data beyond the
                  construction phase.
                </p>
              </li>

              <li>
                <strong>Archiving and Historical Records</strong>
                <p>
                  3D laser scanning preserves a detailed digital record of a
                  project, which can be valuable for future reference,
                  renovations, or historical documentation.
                </p>
              </li>

              <li>
                <strong>Regulatory Compliance</strong>
                <p>
                  Laser scanning can help construction projects meet regulatory
                  requirements by ensuring that the built structures adhere to
                  local codes and standards.
                </p>
              </li>

              <li>
                <strong>Automation</strong>
                <p>
                  As technology advances, the integration of 3D laser scanning
                  with other construction technologies, like Building
                  Information Modeling (BIM) and automation, can further
                  streamline and improve construction processes.
                </p>
              </li>
            </ol>

            <p>
              These benefits underscore the growing importance of 3D laser
              scanning in the construction industry, as it continues to
              transform the way projects are planned, executed, and managed,
              with a focus on precision, efficiency, and cost-effectiveness.
            </p>

            <video
              className="video"
              src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2F3D%2FTRIMMED-ThePlant_BasementZoomed_BlackBackground.mp4?alt=media&token=509f46aa-6fbc-4baa-b247-4c93e5987b39"
              autoPlay
              loop
              muted
              playsInline
              style={{ maxWidth: "1050px" }}
            />

            <h2>How Does It Work?</h2>
            <p>
              We collect 3D laser scans specific to project goals. This data
              improves workflows, resulting in more accurate and realistic
              models. The information collected by our technicians is then
              passed on to our BIM team, who create highly detailed 3D models.
            </p>
            <p>
              Building Information Modeling (BIM) further enhances the
              architectural design process by centralizing construction
              efficiency.
            </p>
            <p>
              The Robotic Imaging Integration is accelerating the building
              industry with key data that can be accessed remotely and at scale.
            </p>
          </div>
        </Content>
      </ContentWrapper>
      <Divider />

      <Content>
        <div
          className="page-navigation"
          style={{ flexDirection: "column", borderBottom: "none" }}
        >
          <ContactRequestContainer />
        </div>
      </Content>
    </SiteWrapper>
  );
}
